import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import "../../utils/normalize.css"
import "../../utils/css/screen.css"

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    const [toggleNav, setToggleNav] = React.useState(false)
    return (
    <>
        <Helmet>
            <html lang={site.lang} />
            <style type="text/css">{`${site.codeinjection_styles}`}</style>
            <body className={bodyClass} />
        </Helmet>

        <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
        <header className="site-head">
        <div className="site-head-container">
            <a
                className="nav-burger"
                href={`#`}
                onClick={() => setToggleNav(!toggleNav)}
            >
                <div
                className="hamburger hamburger--collapse"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
                >
                <div className="hamburger-box">
                    <div className="hamburger-inner" />
                </div>
                </div>
            </a>
            <nav id="swup" class="site-head-left">
                <Navigation data={site.navigation} navClass="site-nav-item" />
            </nav>
            <div className="site-head-center">
                <Link className="site-head-logo" to={`/`}>
                {site.title}
                </Link>
            </div>
            <div className="site-head-right">
                <div className="social-links">
                </div>
            </div>
            </div>
        </header>
        <main id="site-main" className="site-main">
            <div id="swup" className="transition-fade">
            {children}
            </div>
        </main>
        <footer className="site-foot">
            &copy; {new Date().getFullYear()} <Link to={`/`}>{site.title}</Link> &mdash;
            Built with{" "}
            <a
            href="https://gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
            >
            Gatsby
            </a>
        </footer>
    </div>

    </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
